@import '../../../../../../assets/_T1/_config/scss/_colors.scss';

// modules/search.scss
$colors-search: (
    'search-i': color('primary'),
    'search-input': color('secondary'),
    'search-box-bg': color('primary'),
    'search-box-bg-transparent': rgba(68, 68, 68, 0.5),
    'search-box-text': color('text-light'),
    'modal-header': color('primary'),
    'search-box-hover': color('primary'),
    'search-box-text-light': color('text-light'),
    'border-light': color('border', 'light'),
    'text-light': color('text-light'),
    'background-light': color('background', 'light'),
    'background-main': color('background', 'main'),
);

// form/form-login.scss
$colors-form-login: (
    'form-login-bg': color('primary'),
    'form-login-stars': color('secondary'),
    'form-login-a': color('secondary'),
    'form-login-i': color('secondary'),
    'form-login-error-bg': color('error-light'),
    'border-light': color('border', 'light'),
    'form-login-btn-text': color('text-light'),
    'form-login-btn-bg': color('secondary'),
    'form-login-main-bg': color('background', 'main'),
);

// property/saving_search_modal.scss
$colors-saving-search-modal: (
    'modal-stars': color('primary'),
    'background': color('primary'),
    'btn-bg': color('primary'),
);

$colors-user-area: (
    'border-light': color('border', 'light'),
    'user-area-a': color('primary'),
    'underline-animation': color('secondary'),
    'hover': color('secondary'),
);

// property/group_view.scss
$colors-group-view: (
    'group-view-li': color('border','light'),
    'group-view-li-active': color('primary'),
    'group-view-li-hover': color('primary'),
    'group-view-i': color('primary'),
);

//property/single_view.scss
$colors-single-view: (
    'single-view-icon': color('secondary'),
);

//form/default.scss
$colors-form-default: (
    'form-stars': color('primary'),
);
